import { H2Text } from 'components/Typography';
import {
  ConfirmationBackgroundContainer,
  DonateAgainCardsRow,
  DonateAgainContainer,
  ExploreOtherIssuesButton,
} from './styled';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getRecommendationsState } from 'selectors/recommendations';
import { getSetsState } from 'selectors/sets';
import { FC, useEffect } from 'react';
import { getUserState } from 'selectors/user';
import { getRecommendations } from 'store/recommendations/actions';
import { getSets } from 'store/sets/actions';
import { getUrlParams, isKeyInObject } from 'helpers/utils';
import { getIsCheckoutMonthly } from './Form/utils';
import { ITag } from 'store/tags/types';
import { tabIssues as monthlyIssues } from 'screens/Monthly/constants';
import { DonateAgainCard } from './DonateAgainCard';
import theme from 'config/theme';
import Loader from 'components/Loader';

const CARD_COUNT = 3;

interface Props {
  donationAmount: number;
  handleNewDonationSelections: () => void;
  id: string;
}

export const DonateAgain: FC<Props> = ({ donationAmount, handleNewDonationSelections, id }) => {
  const { urlPartner } = getUrlParams();
  const dispatch = useAppDispatch();
  const { recommendations, sets, userState } = useAppSelector(state => ({
    recommendations: getRecommendationsState(state),
    sets: getSetsState(state),
    userState: getUserState(state),
  }));

  useEffect(() => {
    if (!recommendations.data && userState.data) {
      dispatch(getRecommendations());
    }
    if (!sets.data) {
      dispatch(getSets('top-issues'));
    }
  }, [recommendations.data, sets.data]);

  const getRecommendedIssues = () => {
    const recommendedIssues: ITag[] = [];
    const filteredSetTags = sets.data.tags
      .map(tag => ({
        ...tag,
        raised: sets.data.set_tags.find(set => set.tag_id === tag.id)?.tag_fundraising_raised || '',
      }))
      .filter(tag => tag.query_str !== urlPartner && `monthly-${tag.query_str}` !== urlPartner);
    if (getIsCheckoutMonthly()) {
      return filteredSetTags
        .filter(tag => isKeyInObject(tag.query_str, monthlyIssues))
        .slice(0, CARD_COUNT)
        .map(tag => ({ ...tag, query_str: `monthly-${tag.query_str}` }));
    }
    if (!userState.data || !recommendations.data) {
      return filteredSetTags.slice(0, CARD_COUNT);
    }
    for (let i = 0; i < filteredSetTags.length && recommendedIssues.length < CARD_COUNT; i++) {
      const tagIsRecommended = !!recommendations.data.find(
        rec => filteredSetTags[i].query_str === rec.query_str
      );
      const needsRemainingTags =
        filteredSetTags.length - i === CARD_COUNT - recommendedIssues.length;
      if (tagIsRecommended || needsRemainingTags) {
        recommendedIssues.push(filteredSetTags[i]);
      }
    }
    return recommendedIssues;
  };

  return (
    <ConfirmationBackgroundContainer $backgroundColor={theme.shadows.oathBlue(0.1)}>
      <DonateAgainContainer id={id}>
        <H2Text>Donate with One Click</H2Text>
        <DonateAgainCardsRow $justifyContent="center">
          {!sets.data || (userState.data && !recommendations.data) ? (
            <div className="loader-center">
              <Loader width={60} height={60} />
            </div>
          ) : (
            getRecommendedIssues().map(issue => (
              <DonateAgainCard
                key={issue.id}
                donationAmount={donationAmount}
                handleNewDonationSelections={handleNewDonationSelections}
                issue={issue}
                userId={userState?.data?.id}
              />
            ))
          )}
        </DonateAgainCardsRow>
        <ExploreOtherIssuesButton $hasBorder={true} $secondary={true} to="/set?p=top-issues">
          Explore Other Issues
        </ExploreOtherIssuesButton>
      </DonateAgainContainer>
    </ConfirmationBackgroundContainer>
  );
};

import { FC } from 'react';
import { Form } from 'react-final-form';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { BodySmall, Eyebrow } from 'components/Typography';
import { SelectField } from 'components/FinalForm/SelectField';
import { ToggleField } from 'components/FinalForm/ToggleField';
import { DonationTotal } from 'components/Slate/DonationAmount/DonationTotal';
import { noop, required } from 'helpers/utils';
import { TAB_ISSUES_OPTIONS } from 'screens/Monthly/constants';
import { GradientBorderCard } from '../GradientBorderCard';
import { InnerGradientBox, HiddenContainer, ItemTitle, StyledButton, Content } from './styled';

interface Props {
  tabPressed: string | number;
  setTabPressed: (val: string) => void;
  donationAmounts: number[];
  selectedDonationAmount: number | undefined;
  setSelectedDonationAmount: (val: number | undefined) => void;
  buttonText: string;
  isGradientBox?: boolean;
  handleSubmit: () => void;
  keyCard: number;
  borderType: 'full' | 'top';
}

export const DonationCard: FC<Props> = ({
  tabPressed,
  setTabPressed,
  donationAmounts,
  selectedDonationAmount,
  setSelectedDonationAmount,
  buttonText,
  handleSubmit,
  keyCard,
  borderType,
}) => {
  const CardContent = (
    <Content $textAlign="center">
      <ItemTitle>Start Your Monthly Donation</ItemTitle>
      <Spacing $size={16} />
      <Eyebrow $color={theme.colors.inkBlue}>Select your Issue:</Eyebrow>
      <Spacing $size={8} />
      <Form
        onSubmit={noop}
        initialValues={{
          tabIssues: tabPressed,
          toggleIssue1: tabPressed,
          toggleIssue2: tabPressed,
        }}
        render={() => (
          <>
            <HiddenContainer $showInDesktop={true}>
              <Row $justifyContent="center">
                {TAB_ISSUES_OPTIONS.map((item, index) => (
                  <ToggleField
                    key={index}
                    name={`toggleIssue${keyCard}`}
                    value={item.value}
                    onChange={e => setTabPressed(e.target.value)}
                  >
                    <BodySmall $color="inherit">{item.label}</BodySmall>
                  </ToggleField>
                ))}
              </Row>
            </HiddenContainer>
            <HiddenContainer $showInDesktop={false}>
              <SelectField
                name="tabIssues"
                options={TAB_ISSUES_OPTIONS}
                validate={required}
                onChange={setTabPressed}
              />
            </HiddenContainer>
          </>
        )}
      />
      <Spacing $size={4} />
      <DonationTotal
        fromIssues
        donationAmounts={donationAmounts}
        selectedDonationAmount={selectedDonationAmount}
        setSelectedDonationAmount={setSelectedDonationAmount}
        label="Select your monthly contribution amount:"
      >
        <>
          <Spacing $size={16} />
          <StyledButton onClick={handleSubmit}>{buttonText}</StyledButton>
        </>
      </DonationTotal>
    </Content>
  );

  return (
    <GradientBorderCard borderType={borderType}>
      {borderType === 'full' ? (
        <InnerGradientBox>{CardContent}</InnerGradientBox>
      ) : (
        <>{CardContent}</>
      )}
    </GradientBorderCard>
  );
};

import Column from 'components/Column';
import { Image } from 'components/Image';
import { Row } from 'components/Row';
import { H1Text, H2Text, H3 } from 'components/Typography';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const BGContainer = styled.div<{ $isHeaderBG?: boolean }>`
  background-image: url(https://storage.googleapis.com/oath_1/backgrounds/monthly_bg.png);
  padding: 20px;
  ${({ $isHeaderBG }) => (!$isHeaderBG ? 'padding-top: 160px;' : 'padding-bottom: 100px;')};
  background-size: cover;
  ${({ $isHeaderBG }) =>
    $isHeaderBG &&
    `
      @media (min-width: 500px) and (max-width: 800px) {
        padding-bottom: 60px;
      }
      @media (max-width: 500px) {
        padding-bottom: 50px;
      }
    `}
`;

export const HeaderText = styled(Column)`
  padding: 24px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 24px 0;
  }
`;

export const MembersContainer = styled.div`
  margin: 20px;
  margin-top: -100px;

  @media (min-width: 500px) and (max-width: 800px) {
    margin-top: -60px;
  }
  @media (max-width: 500px) {
    margin-top: -50px;
  }
`;

export const HiddenContainer = styled.div<{ $from: number; $to: number }>`
  display: none;

  @media (min-width: ${({ $from }) => $from}px) and (max-width: ${({ $to }) => $to}px) {
    display: block;
  }
`;

export const InfoContainer = styled(Column)`
  padding-right: 32px;

  &:nth-last-child(1) {
    padding-right: 0;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding-right: 0;
    padding-bottom: 16px;

    &:nth-last-child(1) {
      padding-bottom: 0;
    }
  }
`;

export const HowItWorksContainer = styled(Column)`
  margin: 80px 20px;
`;

export const MonthlyContainer = styled(Column)`
  padding: 0 180px;
  margin-bottom: -200px;

  @media (max-width: 1000px) {
    padding: 24px;
  }
  @media (max-width: 500px) {
    margin-bottom: -240px;
  }
`;

export const TextsContainer = styled(Column)`
  max-width: 770px;
  margin: auto;
`;

export const BigText = styled(H2Text)`
  @media (max-width: 1000px) {
    font-size: 30px;
  }
`;

export const ItemTitle = styled(H3)`
  line-height: 44px;
  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 27px;
  }
`;

export const MainTitle = styled(H1Text)`
  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 43px;
  }
`;

export const StyledImage = styled(Image)`
  width: 90px;
  height: 90px;
`;

export const Dot = styled.div<{ $isSelected?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.white : theme.shadows.white(0.3)};
`;

export const DotsRow = styled(Row)`
  padding-top: 16px;
  gap: 12px;
`;

export const MotionContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  align-content: center;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;

export const Carousel = styled.div`
  position: relative;
  overflow: hidden;
  height: 300px;
  width: -webkit-fill-available;

  @media (min-width: 500px) and (max-width: 700px) {
    height: 300px;
  }
  @media (max-width: 500px) {
    height: 350px;
  }
`;

export const ExperienceContainer = styled(Row)`
  padding-top: 40px;
  width: -webkit-fill-available;
`;

export const FAQsContainer = styled.div`
  padding: 0px 240px;

  @media (max-width: 1000px) {
    padding: 0px 20px;
  }
`;

import { FC } from 'react';
import Slider from 'rc-slider';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Body } from 'components/Typography';
import { ModalComponent } from 'components/Modal';
import { localizedDate } from 'helpers/date';
import { abbreviateNum } from 'helpers/utils';
import { IRecipientDetailed } from 'store/recipients/types';
import { HvpTag } from './HvpTag';
import { Title, StyledImage, TextDetails, Description, InlineRow, LabelContainer } from './styled';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  recipient: IRecipientDetailed | undefined;
  isStandard?: boolean;
}

export const SummaryModal: FC<Props> = ({ isOpen, closeModal, recipient, isStandard }) => {
  const draised = parseFloat(recipient?.raised || '0');
  const dcoh = parseFloat(recipient?.coh || '0');
  const rraised = parseFloat(recipient?.competitor?.raised || '0');
  const rcoh = parseFloat(recipient?.competitor?.coh || '0');

  return (
    <ModalComponent isOpen={isOpen} closeModal={closeModal} contentLabel="Summary candidate detail">
      <Row>
        <StyledImage
          $big
          alt={`Recipient ${recipient?.recipient_name}`}
          src={`https://storage.googleapis.com/oath_1/recipients/rec_${recipient?.recipient_id}.jpg`}
          fallbackImgType="candidate"
        />
        <TextDetails>
          <Title $big>{recipient?.recipient_name}</Title>
          <Description $isLight>
            {recipient?.election?.election_name}{' '}
            {recipient?.is_incumbent === null
              ? '(Open)'
              : !recipient?.is_incumbent
              ? '(Challenger)'
              : '(Incumbent)'}
          </Description>
          <Description $isLight>
            {recipient?.is_incumbent === null
              ? 'vs'
              : !recipient?.is_incumbent
              ? 'Challenging Rep.'
              : 'Being challenged by'}{' '}
            {recipient?.competitor?.competitor_name}
          </Description>
        </TextDetails>
      </Row>
      <Spacing $size={16} />
      <Row>
        <LabelContainer>
          <Description>Race Rating:</Description>
        </LabelContainer>
        <TextDetails $width="100%">
          <InlineRow>
            <Column $width="50%">
              <HvpTag text={recipient?.ratings[0] || '-'} />
            </Column>
            <Column $width="50%">
              {recipient?.impact_score && isStandard && (
                <Row $alignItems="center">
                  <Description>Impact Score:</Description>
                  <Spacing $isVertical $size={10} />
                  <Body $color={theme.colors.oathBlue}>{recipient?.impact_score?.toFixed(1)}</Body>
                </Row>
              )}
            </Column>
          </InlineRow>
          <InlineRow>
            <Column $width="50%">
              <Description>{recipient?.pvi || '-'}</Description>
              <Description $isLight $isSmall>
                PVI
              </Description>
            </Column>
            <Column $width="50%">
              <Description>{recipient?.ratings[2] || '-'}</Description>
              <Description $isLight $isSmall>
                Sabato
              </Description>
            </Column>
          </InlineRow>
          <InlineRow>
            <Column $width="50%">
              <Description>{recipient?.ratings[3] || '-'}</Description>
              <Description $isLight $isSmall>
                Inside Elections
              </Description>
            </Column>
            <Column $width="50%">
              <Description>{recipient?.ratings[0] || '-'}</Description>
              <Description $isLight $isSmall>
                Cook Political Report
              </Description>
            </Column>
          </InlineRow>
          <InlineRow>
            <Column>
              <Description>{recipient?.presidential_2020 || '-'}</Description>
              <Description $isLight $isSmall>
                2020 Presidential
              </Description>
            </Column>
          </InlineRow>
        </TextDetails>
      </Row>
      <Spacing $size={8} />
      <Row>
        <LabelContainer>
          <Description>Fundraising:</Description>
        </LabelContainer>
        <TextDetails $width="100%">
          <Description>As of {localizedDate(recipient?.financials_updated_at)}</Description>
          <Spacing $size={4} />
          <Description>{recipient?.recipient_name}</Description>
          <Slider
            min={0}
            max={draised > rraised ? draised : rraised}
            value={dcoh}
            styles={{
              rail: {
                height: 10,
                width: `${draised > rraised ? 100 : (draised * 100) / rraised}%`,
              },
              track: {
                background: theme.colors.oathBlue,
                height: 10,
              },
              handle: {
                display: 'none',
              },
            }}
          />
          <Spacing $size={4} />
          <Row $justifyContent="space-between">
            <Description $isLight $isSmall>
              ${abbreviateNum(dcoh)} Cash on hand
            </Description>
            <Description $isLight $isSmall>
              ${abbreviateNum(draised)} Raised
            </Description>
          </Row>
          {recipient?.competitor && (
            <>
              <Spacing $size={8} />
              <Description>{recipient?.competitor?.competitor_name}</Description>
              <Slider
                min={0}
                max={draised > rraised ? draised : rraised}
                value={rcoh}
                styles={{
                  rail: {
                    height: 10,
                    width: `${rraised > draised ? 100 : (rraised * 100) / draised}%`,
                  },
                  track: {
                    background: theme.colors.errorRed,
                    height: 10,
                  },
                  handle: {
                    display: 'none',
                  },
                }}
              />
              <Spacing $size={4} />
              <Row $justifyContent="space-between">
                <Description $isLight $isSmall>
                  ${abbreviateNum(rcoh)} Cash on hand
                </Description>
                <Description $isLight $isSmall>
                  ${abbreviateNum(rraised)} Raised
                </Description>
              </Row>
            </>
          )}
        </TextDetails>
      </Row>
      <Spacing $size={8} />
      <Row>
        <LabelContainer />
        <TextDetails>
          <Description>
            Learn more on {recipient?.recipient_name}'s{' '}
            <Link href={recipient?.campaign_website} target="_blank">
              Campaign Website
            </Link>
          </Description>
        </TextDetails>
      </Row>
      <Spacing $size={16} />
    </ModalComponent>
  );
};

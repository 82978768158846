import { createActions } from 'reduxsauce';

export interface PayState {
  data: PayResponseData;
  isLoading: boolean;
  error: string;
}

export interface RecipientDonation {
  amount: string;
  recipient_id: string;
}

export type PaymentMethod = 'credit_card' | 'ach';

export interface PayAuthTokenResponse {
  authentication_token: string;
  donation_gid: string;
  created_at: string;
  expires_at: string;
  api_subscriber_id: string;
}

export interface PayResponseData {
  ach_account_number?: string;
  ach_account_type?: string;
  ach_routing_number?: string;
  asynchronous_payment_instructions?: string;
  authcapture_request: boolean;
  authorization_completeness: string;
  authorization_deferred: boolean;
  authtest_request: boolean;
  aux_data: {
    request: {
      server_name: string;
      host: string;
      user_agent: string;
    };
  };
  avs_code?: string;
  cc_brand?: string;
  cc_expiration?: string;
  cc_first_six?: string;
  cc_last_four?: string;
  collection_of_pledge_donation_gid?: string;
  combine_line_items?: string;
  compliance_confirmation?: string;
  compliance_employer?: string;
  compliance_employer_address?: string;
  compliance_employer_address1?: string;
  compliance_employer_address2?: string;
  compliance_employer_city?: string;
  compliance_employer_country?: string;
  compliance_employer_state?: string;
  compliance_employer_zip?: string;
  compliance_occupation?: string;
  compliance_signature_confirmation?: string;
  created_at?: string;
  donor_address1?: string;
  donor_address2?: string;
  donor_city?: string;
  donor_country_code?: string;
  donor_email?: string;
  donor_first_name?: string;
  donor_full_name?: string;
  donor_is_an_organization?: string;
  donor_last_name?: string;
  donor_organization_name?: string;
  donor_phone?: string;
  donor_sortable_name?: string;
  donor_state?: string;
  donor_zip?: string;
  email_opt_in?: string;
  fee_tier?: string;
  gid?: string;
  ip_address?: string;
  is_organizational_contribution: boolean;
  is_pledge_collection_donation: boolean;
  is_pledge_donation: boolean;
  line_item_grouping?: string;
  line_item_grouping_description?: string;
  message?: string;
  payment_method?: PaymentMethod;
  pledge_collection_donation_gids?: string;
  pledge_status?: string;
  ref_code?: string;
  remote_donation_id?: string;
  remote_donor_id?: string;
  remote_donor_organization_id?: string;
  remote_subscription_first?: string;
  remote_subscription_id?: string;
  sharing_token?: string;
  simulate_transactions?: string;
  simulate_transactions_as?: string;
  soft_descriptor?: string;
  source_code?: string;
  store_credit_deposit_amount?: string;
  store_credit_deposit_token?: string;
  store_credit_withdrawal_token?: string;
  subscription_create_data?: string;
  subscription_reference_date?: string;
  subscription_request?: string;
  token?: string;
  token_request?: string;
  uri?: string;
  line_items: PayResponseLineItem[];
}

export interface PayResponseLineItem {
  amount: string;
  aux_data?: string;
  banked_amount: string;
  collected_pledge_amount: string;
  collection_of_pledge_donation_line_item_id?: string;
  exploded_from_proxy_line_item_id?: string;
  is_earmarked: boolean;
  last_reversed_at?: string;
  line_item_id?: string;
  pledge_collection_donation_line_item_ids?: string[];
  pledged_amount: string;
  proxy_banked_amount?: string;
  proxy_specified_amount?: string;
  recipient_id: string;
  recipient_name: string;
  recipient_uri: string;
  reporting_override_queued_at?: string;
  soft_descriptor?: string;
  status: string;
  transaction_amount: string;
  transaction_error?: string;
  transaction_guid?: string;
  transaction_uri: string;
}

export interface SubscriptionRequest {
  type: string;
  expires_after: string;
}

export interface PayRequestData {
  payment_token_id?: string;
  payment_authorization_token?: string;
  line_items: RecipientDonation[];
  donor_last_name: string;
  donor_first_name: string;
  donor_zip: string;
  donor_address1: string;
  donor_city: string;
  donor_state: string; // two-letter
  source_code: string;
  donor_email: string;
  compliance_employer: string;
  compliance_occupation: string;
  cc_last_name: string;
  cc_first_name: string;
  compliance_employer_address1?: string;
  compliance_employer_address2?: string;
  compliance_employer_city?: string;
  compliance_employer_state?: string;
  compliance_employer_zip?: string;
  compliance_employer_country?: string;
  payment_method?: PaymentMethod;
  subscription_request?: SubscriptionRequest;
}

export interface PayTypes {
  PAY: 'PAY';
  PAY_START: 'PAY_START';
  PAY_SUCCESS: 'PAY_SUCCESS';
  PAY_ERROR: 'PAY_ERROR';
  PAY_CLEAN_UP: 'PAY_CLEAN_UP';
}

const { Types, Creators } = createActions<PayTypes>({
  pay: ['data'],
  payStart: null,
  paySuccess: ['data'],
  payError: ['error'],
  payCleanUp: null,
});

export { Types };

export default Creators;

import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import { Row } from 'components/Row';
import { Eyebrow } from 'components/Typography';
import { PressSectionContainer } from './styled';

const baseImgUrl = 'https://storage.googleapis.com/oath_1/static_content/press_logos';
const logoUrlTime = `${baseImgUrl}/Time_Magazine_logo.png`;
const logoUrlCampaignsElections = `${baseImgUrl}/Campaigns___Elections.jpg`;
const logoUrlNYT = `${baseImgUrl}/The_New_York_Times_logo.png`;
const logoUrlBattlefield = `${baseImgUrl}/The-Great-Battlefield-footer-logo-1.svg`;
const logoUrlMediasTouch = `${baseImgUrl}/MediasTouch_logo.jpg`;
const logoPolitico = `${baseImgUrl}/POLITICO_logo.png`;

const pressLogos = [
  logoUrlCampaignsElections,
  logoUrlTime,
  logoUrlNYT,
  logoPolitico,
  logoUrlMediasTouch,
  logoUrlBattlefield,
];
const pressLogosDouble = [...pressLogos, ...pressLogos];

export const PressSection = () => {
  const navigate = useNavigate();

  return (
    <PressSectionContainer>
      <Row $justifyContent="space-around" $alignItems="center">
        <Eyebrow $color={theme.colors.inkBlue}>Oath has been featured in...</Eyebrow>
      </Row>
      <div className="ticker-wrap">
        <div className="ticker">
          <Row $pressable onClick={() => navigate('/gallery')}>
            {[1, 2].map((value, index) => (
              <Row
                $justifyContent="space-between"
                $alignItems="center"
                className={`item-collection-${value}`}
                key={index}
              >
                {pressLogosDouble.map((logoSrc, index) => (
                  <img key={index} className="item" src={logoSrc} />
                ))}
              </Row>
            ))}
          </Row>
        </div>
      </div>
    </PressSectionContainer>
  );
};

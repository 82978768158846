import { FC } from 'react';
import theme from 'config/theme';
import { Row } from 'components/Row';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { abbreviateNum } from 'helpers/utils';
import { primaryDate, regularDate } from 'helpers/date';
import { IRecipientDetailed } from 'store/recipients/types';
import { HvpTag } from './HvpTag';
import { CardContent, StyledImage, TextDetails, Title, Description, CircleIcon } from './styled';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { Body } from 'components/Typography';

interface Props {
  openModal: (value: string) => void;
  recipient: IRecipientDetailed;
  isStandard?: boolean;
}

export const SummaryCard: FC<Props> = ({ openModal, recipient, isStandard }) => {
  const handleLink = () => {
    openModal(recipient.recipient_id);
  };

  return (
    <>
      <GradientBorderCard
        borderType="top"
        borderSize={4}
        gradient={theme.gradients.inkToDarkGradient({})}
      >
        <CardContent $justifyContent="space-between">
          <Row>
            <StyledImage
              alt={`Recipient ${recipient.recipient_name}`}
              src={`https://storage.googleapis.com/oath_1/recipients/rec_${recipient.recipient_id}.jpg`}
              fallbackImgType="candidate"
            />
            <TextDetails>
              <Title>{recipient.recipient_name}</Title>
              <Description $isLight>
                {recipient.election?.election_name}{' '}
                {recipient?.is_incumbent === null
                  ? '(Open)'
                  : !recipient?.is_incumbent
                  ? '(Challenger)'
                  : '(Incumbent)'}
              </Description>
              <Description $isLight>
                {recipient?.is_incumbent === null
                  ? 'vs'
                  : !recipient?.is_incumbent
                  ? 'Challenging Rep.'
                  : 'Being challenged by'}{' '}
                {recipient?.competitor?.competitor_name}
              </Description>
              <Spacing $size={16} />
              <Description>
                ${abbreviateNum(parseFloat(recipient.coh))} cash on hand as of{' '}
                {regularDate(recipient.financials_updated_at)}
              </Description>
              <Row $alignItems="center" $pressable onClick={handleLink} whileTap={{ scale: 0.9 }}>
                <Link $color={theme.colors.jumbo} $align="left">
                  View more Info
                </Link>
                <Spacing $isVertical $size={4} />
                <CircleIcon>
                  <Icon name={IconNames.ChevronCircle} color={theme.colors.black} size={8} />
                </CircleIcon>
              </Row>
            </TextDetails>
          </Row>
          <TextDetails $align="flex-start">
            {recipient.impact_score && isStandard && (
              <>
                <Row $alignItems="center">
                  <Description $isLight $hiddenOnMobile>
                    Impact Score:
                  </Description>
                  <Spacing $isVertical $size={4} />
                  <Body $color={theme.colors.oathBlue}>{recipient.impact_score?.toFixed(1)}</Body>
                </Row>
                <Spacing $size={6} />
              </>
            )}
            <Row $alignItems="center">
              <Description $isLight $hiddenOnMobile>
                PVI:
              </Description>
              <Spacing $isVertical $size={4} />
              <HvpTag text={recipient.pvi} />
            </Row>
            {recipient.ratings?.length && (
              <>
                <Spacing $size={6} />
                <Row $alignItems="center">
                  <Description $isLight $hiddenOnMobile>
                    Race Rating:
                  </Description>
                  <Spacing $isVertical $size={4} />
                  <HvpTag text={recipient.ratings[4]} />
                </Row>
              </>
            )}
            {recipient.election?.primary_election_date && (
              <>
                <Spacing $size={6} />
                <Row $alignItems="center">
                  <Description $isLight $hiddenOnMobile>
                    Primary Date:
                  </Description>
                  <Spacing $isVertical $size={4} />
                  <Description>
                    {primaryDate(recipient.election?.primary_election_date)}
                  </Description>
                </Row>
              </>
            )}
          </TextDetails>
        </CardContent>
      </GradientBorderCard>
      <Spacing $size={10} />
    </>
  );
};

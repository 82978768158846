const TRACKING_PIXEL_IDS = {
  signup: 'AW-11462753602/eGcCCInZ54cZEML67tkq',
  donation: 'AW-16513322465/PMHRCNqslaIZEOGblcI9',
};

const TRACKING_PIXEL_FBQ = {
  signup: 'CompleteRegistration',
  donation: 'InitiateCheckout',
  purchase: 'Purchase',
};

const TRACKING_PIXEL_SAQ = {
  universalPixel: 'UniversalPixel',
};

declare const window: any;
export const trackGtagPixel = (key: string) => {
  try {
    if (Object.keys(TRACKING_PIXEL_IDS).includes(key))
      window.gtag('event', 'conversion', { send_to: TRACKING_PIXEL_IDS[key] });
    if (Object.keys(TRACKING_PIXEL_FBQ).includes(key)) window.fbq('track', TRACKING_PIXEL_FBQ[key]);
    if (Object.keys(TRACKING_PIXEL_SAQ).includes(key)) {
      window.saq('event', TRACKING_PIXEL_SAQ[key]);
    }
  } catch (error) {
    console.warn(error);
  }
};

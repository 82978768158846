export const defaultTrendingIssues = [
  {
    id: 1,
    name: 'Defeat Trump',
    redirect_url: '/donate?p=dt',
    raised: '',
    fundraising_goal: '',
    cta: 'Support Now',
  },
  {
    id: 2,
    name: 'Flip the House',
    redirect_url: '/donate?p=fh',
    raised: '',
    fundraising_goal: '',
    cta: 'Support Now',
  },
  {
    id: 3,
    name: 'Reproductive Rights',
    redirect_url: '/donate?p=rr',
    raised: '',
    fundraising_goal: '',
    cta: 'Support Now',
  },
];

import { FC, useState, useEffect } from 'react';
import theme from 'config/theme';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import CheckBox from 'components/CheckBox';
import { Body, UtilityText } from 'components/Typography';
import Icon, { IconNames } from 'components/Icon';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { getUrlParams, noop } from 'helpers/utils';
import {
  Container,
  Subtitle,
  StyledImage,
  Content,
  CardContent,
  ShowMoreLessRow,
  CandidateGrid,
  GridCheckbox,
  CandidatePhoto,
} from './styled';
import { Details } from './Details';

interface Props {
  index: number;
  item: CandidateType;
  selectItem?: (id: number, check: boolean) => void;
  impactScoreVisibility?: boolean;
}

export const CandidateCard: FC<Props> = ({ index, item, selectItem, impactScoreVisibility }) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const { urlPartner } = getUrlParams();
  const [checkValue, setCheckValue] = useState(item.isSelected);
  const handleCheck = () => {
    setCheckValue(!checkValue);
    selectItem && selectItem(index, !checkValue);
  };

  useEffect(() => {
    setCheckValue(item.isSelected);
  }, [item]);

  const showHideDetail = () => {
    setDetailVisible(prevState => !prevState);
  };

  return (
    <Container>
      <CardContent
        $justifyContent="space-between"
        $alignItems="center"
        $isSelected={checkValue}
        $detailVisible={detailVisible}
      >
        <CandidateGrid>
          <GridCheckbox $pressable onClick={handleCheck}>
            <CheckBox value={checkValue} onChecked={noop} />
          </GridCheckbox>
          <CandidatePhoto $pressable onClick={handleCheck}>
            <Spacing $isVertical $size={8} />
            <StyledImage
              alt={`Recipient ${item.recipient_name}`}
              src={`https://storage.googleapis.com/oath_1/recipients/rec_${item.recipient_id}.jpg`}
              fallbackImgType="candidate"
            />
          </CandidatePhoto>
          <Content onClick={handleCheck}>
            <Body>{item.recipient_name}</Body>
            <Subtitle>{item.display_info}</Subtitle>
            <Subtitle>{item.state}</Subtitle>
          </Content>
          {detailVisible && <Details recipientId={item.recipient_id} />}
          <Column $alignSelf="normal" $alignContent="space-between" $textAlign="right">
            {(!urlPartner || impactScoreVisibility) && item.impact_score && (
              <>
                <Body $color={theme.colors.oathBlue}>{item.impact_score?.toFixed(1)}</Body>
                <Spacing $size={16} />
              </>
            )}
            {(!urlPartner || impactScoreVisibility) && item.impact_score && (
              <ShowMoreLessRow
                $pressable
                $alignItems="center"
                onClick={showHideDetail}
                $detailVisible={!detailVisible}
                $justifyContent="flex-end"
              >
                <UtilityText>{!detailVisible ? 'Show More' : 'Show Less'}</UtilityText>
                <Spacing $isVertical $size={4} />
                <Icon
                  name={!detailVisible ? IconNames.PlusCircle : IconNames.MinusCircle}
                  size={14}
                  color={theme.colors.gray}
                />
              </ShowMoreLessRow>
            )}
          </Column>
        </CandidateGrid>
      </CardContent>
      {(!urlPartner || impactScoreVisibility) && item.impact_score && (
        <ShowMoreLessRow
          $pressable
          $alignItems="center"
          onClick={showHideDetail}
          $detailVisible={detailVisible}
          $detailOpen={true}
        >
          <UtilityText>{!detailVisible ? 'Show More' : 'Show Less'}</UtilityText>
          <Spacing $isVertical $size={4} />
          <Icon
            name={!detailVisible ? IconNames.PlusCircle : IconNames.MinusCircle}
            size={14}
            color={theme.colors.gray}
          />
        </ShowMoreLessRow>
      )}
    </Container>
  );
};

import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ButtonBase, ButtonBrand } from 'components/ButtonBrand';
import { Row } from 'components/Row';
import { BodySmall } from 'components/Typography';
import { Image } from 'components/Image';
import Column from 'components/Column';

export const CheckoutContainer = styled(Row)`
  ${({ theme }) => theme.fonts.regular};
  flex-direction: column;
  padding: 17px 43px;
  width: 100%;
  @media (max-width: 965px) {
    padding: 16px 12px;
    width: auto;
  }
`;

export const FormPage = styled.div`
  border: ${({ theme }) => `1px solid ${theme.shadows.black(0.2)}`};
  border-radius: 20px;
  padding: 14px 20px;
  width: 365px;
  @media (max-width: 965px) {
    width: auto;
  }
`;

export const FormStyled = styled.form`
  margin: 23px auto 0px;
  @media (max-width: 965px) {
    margin: 17px 0px 0px;
  }
`;

export const NavigationContainer = styled(Row)`
  border-bottom: ${({ theme }) => `1px solid ${theme.shadows.black(0.3)}`};
  list-style-type: none;
  margin: 0 0 10px;
  padding: 0;
  position: relative;
  width: 100%;
`;

export const OlNavigation = styled.ol`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0 0 7px;
  padding: 0;
  position: relative;
  width: 100%;
  li {
    display: flex;
  }
  a,
  button {
    background: none;
    border: none;
    outline: none;
    text-align: left;
    border-radius: 0px;
    color: ${({ theme }) => theme.colors.inkBlue};
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    outline: inherit;
    padding: 0;
    text-decoration: none;
  }
  a:focus-visible,
  button:focus-visible {
    outline: ${({ theme }) => `1px solid ${theme.colors.oathBlue}`};
  }
  a:focus:not(:focus-visible),
  button:focus:not(:focus-visible) {
    outline: none;
  }
  a:disabled,
  a[disabled],
  button:disabled,
  button[disabled] {
    pointer-events: none;
  }
  @media (max-width: 965px) {
    li:nth-child(1) {
      padding-right: 16px;
    }
    li:nth-child(2),
    li:nth-child(3) {
      padding-right: 5px;
    }
  }
`;

export const NavigationAmountDetails = styled(BodySmall)`
  @media (max-width: 965px) {
    display: none;
  }
`;

const getNextPrevStyles = theme => `
  opacity: 0.7;
  margin-bottom: 4px;
  color: ${theme.colors.black};
  &:focus-visible {
    outline: 1px solid ${theme.colors.oathBlue};
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const NextPrevButton = styled(ButtonBase)`
  background: none;
  ${({ theme }) => getNextPrevStyles(theme)}
`;

export const NextPrevLink = styled(Link)`
  text-decoration: none;
  ${({ theme }) => getNextPrevStyles(theme)}
`;

export const SubmitButton = styled(ButtonBrand)<{ $submitting?: boolean }>`
  width: 100%;
  &[disabled] {
    ${({ theme }) =>
      `background: linear-gradient(to right, ${theme.colors.electricBlue}, ${theme.colors.darkGreen}, ${theme.colors.oathBlue})`};
    pointer-events: none;
  }
`;

export const DetailsColumn = styled(Row)`
  flex-direction: column;
  gap: 8px;
  margin-bottom: 2px;
  margin-top: 4px;
`;

export const DetailsRow = styled(Row)<{ $gap?: string }>`
  gap: ${({ $gap }) => $gap || '5px'};
  div {
    flex: 1;
    min-width: 0px;
    -webkit-flex: 1;
  }
`;

export const RequiredText = styled.div`
  color: ${({ theme }) => theme.colors.gray};
`;

export const HR = styled.hr`
  background: ${({ theme }) => theme.shadows.black(0.2)};
  border: 0;
  height: 1px;
  margin-bottom: 12px;
  margin-top: 14px;
  width: 100%;
`;

export const PaymentTypesRow = styled(Row)`
  gap: 72px;
`;

export const ConfirmationBackgroundContainer = styled.div<{ $backgroundColor?: string }>`
  background: ${({ theme, $backgroundColor }) => $backgroundColor || theme.colors.paleBlue};
  padding: 30px 20px;
  @media (min-width: 1030px) {
    padding: 38px 121px 50px;
  }
`;

export const ConfirmationContainer = styled(Row)`
  align-items: center;
  gap: 50px;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1150px;
  @media (min-width: 1030px) {
    align-items: flex-start;
    flex-direction: row;
    gap: 48px;
  }
`;

export const ConfirmationCardContainer = styled.div`
  width: 100%;
  @media (min-width: 450px) {
    width: 365px;
  }
`;

export const ConfirmationCardTitle = styled(Row)`
  justify-content: center;
  margin: 2px 0;
`;

export const ConfirmationCardContent = styled.div`
  padding: 13px 20px 18px;
  text-align: left;
`;

export const ConfirmationImage = styled(Image)`
  margin: 53px 0 26px;
  max-height: 85px;
  max-width: 80%;
  @media (max-width: 1030px) {
    margin: 20px 0 0;
  }
`;

export const ConfirmationButtonRow = styled(Row)`
  gap: 8px;
  margin: 27px 0 44px;
  width: 365px;
  button,
  a {
    flex: 1;
    width: 100%;
  }
  @media (max-width: 1030px) {
    flex-direction: column;
    margin: 20px 0 0;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const ProcessingRow = styled(Row)`
  justify-content: center;
  margin-top: 6px;
  text-align: center;
`;

export const ConfirmationPaymentDisplay = styled.div`
  text-transform: capitalize;
`;

export const ProgressBar = styled.div<{ $width: string }>`
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.electricBlue}, ${theme.colors.darkGreen}, ${theme.colors.oathBlue})`};
  border-radius: 3px;
  bottom: -2px;
  height: 3px;
  position: absolute;
  width: ${({ $width }) => $width};
`;

export const ToggleGroup = styled(Row)`
  width: 100%;
`;

export const IconCircle = styled(Row)<{ $color: string }>`
  background: ${({ theme, $color }) => theme.colors[$color]};
  border-radius: 30px;
  height: 17px;
  margin-right: 7px;
  min-width: 17px;
  width: 17px;
`;

export const PaymentStatusRow = styled(Row)`
  margin-top: 7px;
`;

export const Checkbox = styled(Row)<{ $isChecked: boolean }>`
  ${({ theme, $isChecked }) => $isChecked && `background: ${theme.colors.oathBlue}`};
  border: ${({ theme }) => `1px solid ${theme.colors.black}`};
  border-radius: 2px;
  height: 15px;
  margin-right: 8px;
  width: 15px;
`;

export const CheckboxRow = styled(Row)`
  color: ${({ theme }) => theme.colors.shaft};
`;

export const PaymentCheckboxRow = styled(CheckboxRow)`
  margin-top: 7px;
`;

export const PaymentsColumn = styled(Row)`
  flex-direction: column;
  gap: 7px;
`;

export const IFrameContainer = styled.div`
  width: 100%;
  height: 600px;
  padding-top: 32px;
`;

export const IFrame = styled.iframe`
  border: none;
  width: 70%;
  height: 100%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const NameValidationError = styled.div`
  margin-bottom: 5px;
`;

export const ProcessingCardContent = styled(Column)`
  padding: 37px 0;
  text-align: center;
`;

export const ProcessingImage = styled(Image)`
  height: 34px;
  margin: 0 auto 34px;
`;

export const DonateAgainContainer = styled.div`
  margin: 0 auto;
  max-width: 1150px;
  text-align: center;
`;

export const RecipientsContainer = styled(Row)`
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 4px;
`;

export const Recipient = styled.div`
  min-width: calc(50% - 8px);
  flex-grow: 1;
`;

export const DonateAgainCardsRow = styled(Row)`
  align-items: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 37px;
  margin-left: auto;
  margin-right: auto;
  max-width: 375px;
  @media (min-width: 1030px) {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
`;

export const DonateAgainCardContainer = styled(Column)`
  flex: 0 0 calc(33.3333% - 36px);
`;

export const DonateAgainCardLearnMore = styled.div`
  bottom: 22px;
  position: absolute;
`;

export const ExploreOtherIssuesButton = styled(ButtonBrand)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  @media (min-width: 800px) {
    margin-top: 46px;
    width: 375px;
  }
`;

export const ConfirmationContainerLeft = styled(Row)`
  align-items: center;
  text-align: left;
  flex-direction: column;
  @media (min-width: 1030px) {
    align-items: flex-start;
  }
`;

export const ConfirmationContainerRight = styled(Row)`
  align-items: center;
  flex-direction: column;
`;

export const DonateAgainJumpButton = styled(ButtonBrand)`
  @media (min-width: 1030px) {
    display: none;
  }
`;

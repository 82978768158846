import { Hero } from 'components/Hero';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { PressSection } from 'screens/Landing/PressSection';
import { Recommendations } from './Recommendations';
import { Marketing } from './Marketing';
import { Community } from './Community';

export const GetStartedScreen = () => {
  return (
    <>
      <Bar />
      <Hero
        title="Support Causes You Care About"
        subtitle="Oath provides personalized Democratic donation recommendations so your dollars have a bigger impact."
        ctaText="Get started"
        cta="/login"
        media="https://storage.googleapis.com/oath_1/static_content/getstarted/video_hero.mp4"
      />
      <Marketing />
      <Recommendations />
      <Community />
      <PressSection />
      <Hero
        isMonthly
        title="Oath’s Monthly Membership"
        subtitle="Make an impact and join thousands of donors who get data-driven donation recommendations without all the spam now."
        ctaText="Learn More"
        cta="/monthly"
        media="https://storage.googleapis.com/oath_1/static_content/getstarted/image_monthly.png"
      />
      <Footer />
    </>
  );
};

import { Hero } from 'components/Hero';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { PressSection } from './PressSection';
import { TrendingSection } from './TrendingSection';
import { LearnMoreSection } from './LearnMoreSection';
import { PoweredBySection } from './PoweredBySection';
import { GetStartedSection } from './GetStartedSection';
import { GetRecommendations } from './GetRecommendations';
import { Container, Content } from './styled';
import { useEffect } from 'react';
import { trackGtagPixel } from 'helpers/gtag';

const redirectToDonateIfNeeded = (currentUrl: string) => {
  const url = new URL(currentUrl);
  const searchParams = url.searchParams;

  if (searchParams.has('tags') || searchParams.has('p')) {
    window.location.href = `/donate?${searchParams.toString()}`;
  }
  return;
};

export const LandingScreen = () => {
  redirectToDonateIfNeeded(window.location.href);

  useEffect(() => {
    trackGtagPixel('universalPixel');
  }, []);

  return (
    <Container>
      <Bar />
      <Content>
        <Hero
          title="The smartest way to do your political giving."
          subtitle="Oath empowers donors to give more strategically by making data-driven recommendations about where your dollars have maximum impact."
          ctaText="Sign up now"
          cta="/login"
        />
        <PressSection />
        <GetStartedSection />
        <TrendingSection />
        <GetRecommendations />
        <LearnMoreSection />
        <PoweredBySection />
      </Content>
      <Footer />
    </Container>
  );
};

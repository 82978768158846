import styled from 'styled-components/macro';

const BaseText = styled.span<{ $color?: string; $fontType?: string }>`
  color: ${({ $color, theme }) => $color || theme.colors.inkBlue};
  ${({ theme, $fontType }) => $fontType && theme.fonts[$fontType]};
`;

export const Eyebrow = styled.div<{ $color?: string }>`
  color: ${({ $color, theme }) => $color || theme.colors.oathBlue};
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 14px;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.semiBold};
`;

export const H1Text = styled(BaseText)`
  font-size: 60px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
  letter-spacing: -0.01em;
  line-height: 65px;
`;

export const H2Text = styled.span<{ $color?: string; $italic?: boolean }>`
  color: ${({ $color, theme }) => $color || theme.colors.inkBlue};
  font-size: 40px;
  letter-spacing: -0.01em;
  line-height: 44px;
  ${({ theme, $italic }) => ($italic ? theme.fonts.italic : theme.fonts.light)};
`;

export const H3 = styled(BaseText)`
  font-size: 30px;
  letter-spacing: -0.01em;
  line-height: 33px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
`;

export const BodyBig = styled(BaseText)`
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -0.01em;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;

export const H4 = styled(BaseText)`
  font-size: 20px;
  line-height: 25px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
`;

export const Body = styled(BaseText)`
  font-size: 15px;
  line-height: 20px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;

export const BodySmall = styled(BaseText)`
  font-size: 13px;
  line-height: 18px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;

export const UtilityText = styled(BaseText)`
  font-size: 11px;
  line-height: 16px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;

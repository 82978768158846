import { FC } from 'react';
import Spacing from 'components/Spacing';
import { Container, StyledImage, Label, Title } from './styled';

interface Props {
  name: string;
  title: string;
  imgID?: string;
  recID?: number;
  isRecipient?: boolean;
  width?: string;
}

export const Member: FC<Props> = ({
  name,
  title,
  imgID = 'recipient_default',
  recID,
  isRecipient,
  width,
}) => {
  return (
    <Container $justifyItems="center" $textAlign="center" $width={width}>
      <StyledImage
        alt="Recipient headshot"
        src={`https://storage.googleapis.com/oath_1/${
          isRecipient ? `recipients/rec_${recID}.jpg` : `headshots/${imgID.toLowerCase()}.jpeg`
        }`}
      />
      <Spacing $size={15} />
      <Label>{name}</Label>
      <Spacing $size={4} />
      <Title>{title}</Title>
    </Container>
  );
};

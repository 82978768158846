import { FC } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Button from 'components/Button';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { getPathWithRef } from 'helpers/utils';
import {
  Container,
  FooterColumn,
  FooterDivider,
  Label,
  LabelContainer,
  Logo,
  PolicyContainer,
  Title,
} from './styled';

const privacyPolicyUrl =
  'https://storage.googleapis.com/oath_1/legal/Oath%20-%20Privacy%20Policy%20and%20TOS%20Dec%202023.docx.pdf';

interface Props {
  background?: string;
}

export const Footer: FC<Props> = () => {
  return (
    <>
      <FooterDivider />
      <Container>
        <LabelContainer>
          <FooterColumn>
            <Logo
              alt="Oath logo"
              src="https://storage.googleapis.com/oath_1/static_content/oath_logos/Oath_FullLockup_Color_LightText.svg"
            />
            <Label>
              Oath is your one-stop-shop for political giving. Our mission is to empower donors to
              participate in our democracy by offering civic education & strategic investment
              opportunities.
            </Label>
            <Link $color={theme.colors.white} href={getPathWithRef('/our_team')}>
              Learn More About Us
            </Link>
          </FooterColumn>
          <FooterColumn>
            <Spacing $size={30} />
            <Label $isBold>ABOUT OATH</Label>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/donor')}>
              Our Mission
            </Link>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/our_team')}>
              Our Team
            </Link>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/contact')}>
              Contact Us
            </Link>
          </FooterColumn>
          <FooterColumn>
            <Spacing $size={30} />
            <Label $isBold>LEARN MORE</Label>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/impact_score')}>
              Our Algorithm
            </Link>
            <Link
              $color={theme.colors.white}
              $hideUnderline
              href={getPathWithRef('/set?p=top-issues')}
            >
              Top Issues
            </Link>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/donate')}>
              Search Candidates
            </Link>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/monthly')}>
              Donate Monthly
            </Link>
            <Link $color={theme.colors.white} $hideUnderline href={getPathWithRef('/gallery')}>
              In the Media
            </Link>
          </FooterColumn>
          <FooterColumn>
            <Spacing $size={30} />
            <Label $isBold>LEGAL & COMPLIANCE</Label>
            <Link
              $color={theme.colors.white}
              $hideUnderline
              href={privacyPolicyUrl}
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              $color={theme.colors.white}
              $hideUnderline
              href={privacyPolicyUrl}
              target="_blank"
            >
              Terms of Use
            </Link>
          </FooterColumn>
          <FooterColumn>
            <Spacing $size={30} />
            <Button
              text="SUPPORT MAX IMPACT RACES"
              onPress={() =>
                window.open(getPathWithRef(`${ENV_CONFIG().SITE_URL}/donate?p=mx`), '_blank')
              }
              backgroundColor={theme.colors.white}
              textColor={theme.colors.inkBlue}
            />
            <Spacing $size={14} />
            <Label $isBold>STAY CONNECTED</Label>
            <Row>
              <Icon
                onClick={() => window.open('https://www.instagram.com/oath.vote/', '_blank')}
                name={IconNames.Instagram}
                size={22}
                color={theme.colors.white}
                style={{ cursor: 'pointer' }}
              />
              <Spacing $isVertical $size={16} />
              <Icon
                onClick={() => window.open('https://www.linkedin.com/company/giveoath', '_blank')}
                name={IconNames.Linkedin}
                size={22}
                color={theme.colors.white}
                style={{ cursor: 'pointer' }}
              />
            </Row>
          </FooterColumn>
        </LabelContainer>
        <PolicyContainer>
          <Title>
            © All Rights Reserved {new Date().getFullYear().toString()}. Not authorized or
            controlled by any candidate, candidate’s agent, campaign committee, or party committee.
            No candidate is responsible for our activities. Pol. Adv. Prepared and paid for by 51C
            Collaborative, 8 The Greene, Suite B, Dover, DE 19901; Brian Derrick, CEO.
          </Title>
        </PolicyContainer>
      </Container>
    </>
  );
};

import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

const CTA = ({ children, ...props }) => {
  if (props.href) {
    return <a {...props}>{children}</a>;
  }
  if (props.to) {
    const { to, ...rest } = props;
    return (
      <RouterLink to={to} {...rest}>
        {children}
      </RouterLink>
    );
  }
  return <button {...props}>{children}</button>;
};

export const Link = styled(CTA)<{
  $color?: string;
  $fontType?: string;
  $hasUnderline?: boolean;
  $textAlign?: string;
  $fontSize?: string;
}>`
  background: none;
  border: none;
  color: ${({ $color }) => $color || 'inherit'};
  ${({ theme, $fontType }) => ($fontType && theme.fonts[$fontType]) || theme.fonts.regular};
  cursor: pointer;
  padding: 0;
  outline: inherit;
  font-size: ${({ $fontSize }) => $fontSize || '13px'};
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  text-decoration: ${({ $hideUnderline }) => ($hideUnderline ? 'none' : 'underline')};
  span:hover,
  &:hover span,
  &:hover {
    color: ${({ theme }) => theme.colors.electricBlue};
    text-decoration: underline;
  }
`;

import { ButtonBrand } from 'components/ButtonBrand';
import { Row } from 'components/Row';
import styled from 'styled-components/macro';
import { H1Text, H4 } from 'components/Typography';
import { Image } from 'components/Image';

export const Title = styled(H1Text)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 43px;
  }
`;

export const HeroContainer = styled.div<{ $isMonthly?: boolean }>`
  ${({ $isMonthly, theme }) =>
    $isMonthly
      ? `background-size: cover; background-image: url(https://storage.googleapis.com/oath_1/backgrounds/monthly_bg.png)`
      : `background: linear-gradient(139.04deg, ${theme.colors.oathBlue} 26.51%, ${theme.colors.inkBlue} 77.67%)`};
  padding: 84px 122px 77px;
  @media (max-width: 900px) {
    padding: 37px 20px 67px;
  }
`;

export const LandingButton = styled(ButtonBrand)`
  @media (max-width: 600px) {
    display: block;
    text-align: center;
  }
`;

export const Subtitle = styled(H4)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 25px;
  margin-top: 15px;
`;

export const HeroContentImage = styled.div`
  flex: 1 1 0;
  max-width: 658px;
`;

export const HeroContentText = styled.div`
  flex: 2 1 0;
  max-width: 658px;
`;

export const HeroText = styled(Row)`
  flex-direction: column;
`;

export const StyledImage = styled(Image)`
  max-width: 495px;
  width: 100%;
  @media (max-width: 900px) {
    max-width: 900px;
  }
`;

export const StyledVideo = styled.video`
  max-width: 495px;
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 900px) {
    max-width: 900px;
  }
`;

export const HeroContentRow = styled(Row)`
  gap: 110px;
  @media (max-width: 1256px) {
    gap: 50px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

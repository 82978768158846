import { Row } from 'components/Row';
import { getPathWithRef } from 'helpers/utils';
import {
  GetStartedSectionContainer,
  H2,
  LandingButton,
  SectionContent,
  SectionImage,
  SectionImageContainer,
  SectionRow,
  SectionSubheader,
} from './styled';

const image = 'https://storage.googleapis.com/oath_1/static_content/landing_images/oath-image1.png';

export const GetStartedSection = () => {
  return (
    <GetStartedSectionContainer>
      <Row $justifyContent="space-around">
        <div>
          <SectionRow $justifyContent="space-between" $alignItems="center">
            <SectionContent>
              <H2>Maximize the impact of your donations</H2>
              <SectionSubheader>
                We analyze thousands of elections from President to school board to find which
                candidates need your support the most. We provide our recommendations for free and
                we don’t sell or share your contact information.
              </SectionSubheader>
              <LandingButton href={getPathWithRef('/donate?p=mx')}>
                Support Oath’s Top 5
              </LandingButton>
            </SectionContent>
            <SectionImageContainer>
              <SectionImage src={image} />
            </SectionImageContainer>
          </SectionRow>
        </div>
      </Row>
    </GetStartedSectionContainer>
  );
};

import { CandidateImage as CandidateImageStyled, CandidateImagePlaceholder } from '../styled';

type CandidateImageProps = {
  recipientId: string | number;
};

export const CandidateImage = ({ recipientId }: CandidateImageProps) => {
  return (
    <CandidateImageStyled
      alt="Recipient"
      src={`https://storage.googleapis.com/oath_1/recipients/rec_${recipientId}.jpg`}
      fallbackPlaceholder={<CandidateImagePlaceholder />}
    />
  );
};

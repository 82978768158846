import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import { FAQs } from 'components/Faqs';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { Eyebrow } from 'components/Typography';
import { getPathWithRef, scrollToTop } from 'helpers/utils';
import { Container, Description } from './styled';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getFaqs } from 'store/faqs/actions';
import { getFaqsData } from 'selectors/faqs';

export const ContactScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const faqsData = useAppSelector(getFaqsData);

  useEffect(() => {
    scrollToTop();
    dispatch(getFaqs('contact'));
  }, []);

  return (
    <>
      <Bar />
      <Container>
        {faqsData?.length > 0 && <FAQs align="left" />}
        <Eyebrow>Let's chat!</Eyebrow>
        <Spacing $size={16} />
        <Description>
          Check out our{' '}
          <Link
            href={'https://storage.googleapis.com/oath_1/support/Oath%20FAQs.pdf'}
            target="_blank"
            $fontSize="inherit"
          >
            FAQs here
          </Link>
          . If your question isn't answered, please leave your comments & questions below. Someone
          from Team Oath will be in touch shortly!
        </Description>
        <Spacing $size={16} />
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/appPQgaEeAQXJmjVx/shrZPDf5bU85dQqA2?backgroundColor=tealDusty%22"
          width="100%"
          height="533"
          style={{ background: theme.colors.transparent, border: `1px solid ${theme.colors.alto}` }}
        />
        <Spacing $size={32} />
        <Eyebrow>What is Oath?</Eyebrow>
        <Spacing $size={16} />
        <Description>
          At Oath, our mission is to empower donors to actively participate in our democracy by
          offering civic education and strategic investment opportunities. Founded on the principle
          of donor empowerment, Oath provides independent, data-driven recommendations, enabling
          donors to make impactful contributions based on their issue interests, geographic focus,
          or other relevant criteria. Oath keeps all your donation and personally identifiable
          information private with data privacy as our highest priority. You can learn more about{' '}
          <Link onClick={() => navigate(getPathWithRef('/impact_score'))} $fontSize="inherit">
            Oath’s Impact Score here.
          </Link>
        </Description>
        <Spacing $size={32} />
      </Container>
      <Footer />
    </>
  );
};

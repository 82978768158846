import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const Row = styled(motion.div)<{
  $justifyContent?: string;
  $alignItems?: string;
  $pressable?: boolean;
}>`
  justify-content: ${({ $justifyContent }) => $justifyContent || 'inherit'};
  align-items: ${({ $alignItems }) => $alignItems || 'inherit'};
  ${({ $pressable }) => $pressable && 'cursor: pointer;'};
  display: flex;
`;

export const RowToGrid = styled(Row)<{ $switchToGrid?: number }>`
  ${({ $switchToGrid }) => `
    @media (max-width: ${$switchToGrid || 1000}px) {
      display: grid;
    }
  `}
`;

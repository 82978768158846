import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ENV_CONFIG } from 'config/environment';
import Error from 'components/Error';
import Loader from 'components/Loader';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Body } from 'components/Typography';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUrlParams, scrollToTop } from 'helpers/utils';
import {
  democracyEngineCheckoutRedirect,
  getDonationSelectionsObj,
  getProcessingRedirect,
  getUrlSearchParamsStrWithoutRecipients,
  saveDonationSelectionsFromUrl,
} from 'helpers/donate';
import { useAuth } from 'hooks/auth';
import { getPayData } from 'selectors/pay';
import { getAllTags } from 'selectors/tags';
import { getUserState } from 'selectors/user';
import { getPaymentsData, getPaymentsState } from 'selectors/payments';
import { clearDonationSelections, getRedirectAfterLogin } from 'services/storage';
import { getTags } from 'store/tags/actions';
import { getUserProfile } from 'store/user/actions';
import { getPayments } from 'store/payments/actions';
import { ProcessingRedirectOptions } from 'store/tags/constants';
import { CheckoutFlow } from './CheckoutFlow';
import { Confirmation } from './Confirmation';
import { checkoutLogFirebaseEvent } from './Form/utils';
import { getRecipientsData } from 'selectors/recipients';
import { getRecipients } from 'store/recipients/actions';
import { trackGtagPixel } from 'helpers/gtag';

const beforeUnloadHandler = (event: Event) => {
  // Recommended
  event.preventDefault();

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};

const isRemainingInDonateFlow = () => {
  return (
    (window.location.pathname === '/login' && getRedirectAfterLogin()) ||
    window.location.pathname === '/donate' ||
    window.location.pathname === '/checkout'
  );
};

export const CheckoutScreen = () => {
  const { urlPartner } = getUrlParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth0();
  const [processingRedirect, setProcessingRedirect] = useState(ProcessingRedirectOptions.DE_OATH);
  const [donationAmount, setDonationAmount] = useState<number>(0);
  const [candidates, setCandidates] = useState<CandidateType[]>([]);
  const [shouldRedirectToDE, setShouldRedirectToDE] = useState(false);
  const [areDonationSelectionsReady, setAreDonationSelectionsReady] = useState(false);
  const { allTags, payment, payments, paymentsState, recipients, userState } = useAppSelector(
    state => ({
      allTags: getAllTags(state),
      payment: getPayData(state),
      payments: getPaymentsData(state),
      paymentsState: getPaymentsState(state),
      recipients: getRecipientsData(state),
      userState: getUserState(state),
    })
  );
  const isCheckoutV2Enabled = true;

  const redirectToDE = (candidates, donationAmount) => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
    const { candidates: storedCandidates, total: storedDonationAmount } =
      getDonationSelectionsObj();
    const savedCandidates = candidates || storedCandidates;
    const savedDonationAmount = donationAmount || storedDonationAmount;
    if (!savedCandidates?.length || !savedDonationAmount) {
      navigate(`/donate?${getUrlSearchParamsStrWithoutRecipients()}`);
      return;
    }
    clearDonationSelections();
    democracyEngineCheckoutRedirect(savedCandidates, savedDonationAmount);
    return;
  };

  const handleNewDonationSelections = () => {
    const { candidates: savedCandidates, total: savedDonationAmount } = getDonationSelectionsObj();
    if (savedCandidates) {
      setCandidates(savedCandidates);
    }
    if (savedDonationAmount) {
      setDonationAmount(Number(savedDonationAmount));
    }
    if (!savedCandidates || !savedDonationAmount) {
      navigate(`/donate?${getUrlSearchParamsStrWithoutRecipients()}`);
    }
  };

  useAuth(() => {
    dispatch(getUserProfile());
    dispatch(getPayments());
  });

  useEffect(() => {
    if (candidates && donationAmount && shouldRedirectToDE) {
      redirectToDE(candidates, donationAmount);
    }
  }, [candidates, donationAmount, shouldRedirectToDE]);

  useEffect(() => {
    if (!allTags) {
      dispatch(getTags({ urlPartner }));
    }
    if (!recipients) {
      dispatch(
        getRecipients({
          amount: 200,
          query_str: urlPartner,
        })
      );
    }
    scrollToTop();
    trackGtagPixel('universalPixel');
  }, []);

  useEffect(() => {
    if (
      !payment &&
      !isAuthLoading &&
      (isCheckoutV2Enabled || payments?.length || isAuthenticated)
    ) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }

    return () => {
      if (payment || !isRemainingInDonateFlow()) {
        clearDonationSelections();
      }
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [isAuthLoading, isAuthenticated, payments, payment, candidates, donationAmount]);

  useEffect(() => {
    if (recipients && !areDonationSelectionsReady) {
      saveDonationSelectionsFromUrl(recipients);
      setAreDonationSelectionsReady(true);
    }
  }, [recipients, areDonationSelectionsReady]);

  useEffect(() => {
    if (!areDonationSelectionsReady) {
      return;
    }
    handleNewDonationSelections();
  }, [areDonationSelectionsReady]);

  useEffect(() => {
    if (
      !isCheckoutV2Enabled &&
      (payments?.length === 0 || paymentsState.error || userState.error)
    ) {
      setShouldRedirectToDE(true);
    }
  }, [payments, paymentsState.error, userState.error]);

  useEffect(() => {
    if (isAuthLoading) {
      return;
    }
    if (!isAuthenticated && !isCheckoutV2Enabled) {
      setShouldRedirectToDE(true);
      return;
    }
    if (!userState.data && !isCheckoutV2Enabled) {
      return;
    }
    if (userState?.data?.id || isCheckoutV2Enabled) {
      checkoutLogFirebaseEvent({
        eventName: 'view',
        userId: userState?.data?.id || '',
        candidates,
        donationAmount,
      });
      return;
    }
  }, [isAuthLoading, isAuthenticated, userState.data?.id]);

  useEffect(() => {
    if (allTags?.length) {
      const updatedProcessingRedirect = getProcessingRedirect(allTags);
      setProcessingRedirect(updatedProcessingRedirect);
    }
  }, [allTags?.length]);

  if (urlPartner && processingRedirect !== ProcessingRedirectOptions.DE_OATH) {
    setShouldRedirectToDE(true);
  }

  const isLoading =
    !allTags?.length ||
    (!isCheckoutV2Enabled && !payments?.length) ||
    (!isCheckoutV2Enabled && !userState?.data) ||
    isAuthLoading ||
    (isAuthenticated && !payments);

  return (
    <>
      {(!allTags?.length || !urlPartner || (allTags?.length > 0 && !allTags[0].whitelabeled)) && (
        <Header urlPartner={urlPartner} />
      )}
      {isLoading ? (
        <div className="loader-center">
          <Loader width={60} height={60} />
        </div>
      ) : (
        <Routes>
          <Route
            index
            element={
              <ErrorBoundary
                fallback={
                  <Error
                    data={{ component: 'Checkout Flow' }}
                    errorMsg="Failure with donate Checkout Flow component"
                    shouldAlert={true}
                  >
                    <Body>We encountered an error. Please contact support.</Body>
                  </Error>
                }
              >
                <CheckoutFlow
                  candidates={candidates}
                  donationAmount={donationAmount}
                  payments={payments}
                  user={userState.data}
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="/confirmation"
            element={
              <ErrorBoundary
                fallback={
                  <Error
                    data={{ component: 'Checkout Confirmation' }}
                    errorMsg="Failure with donate Checkout Confirmation component"
                    shouldAlert={true}
                  >
                    <Body>We encountered an error. Please contact support.</Body>
                  </Error>
                }
              >
                <Confirmation
                  candidates={candidates}
                  candidateCount={candidates.length}
                  donationAmount={donationAmount}
                  handleNewDonationSelections={handleNewDonationSelections}
                  pageImage={
                    urlPartner &&
                    `${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${
                      allTags[0].id
                    }.png`
                  }
                />
              </ErrorBoundary>
            }
          />
        </Routes>
      )}
      <Footer />
    </>
  );
};

import { Row } from 'components/Row';
import {
  H2,
  LandingButton,
  LearnMoreSectionContainer,
  SectionContent,
  SectionImage,
  SectionVideo,
  SectionImageContainer,
  SectionRow,
  SectionSubheader,
} from './styled';
import { getPathWithRef } from 'helpers/utils';

const video = 'https://storage.googleapis.com/oath_1/static_content/landing_images/Oath_Map.mp4';
const image = 'https://storage.googleapis.com/oath_1/static_content/landing_images/us-map.png';

export const LearnMoreSection = () => {
  return (
    <LearnMoreSectionContainer>
      <Row $justifyContent="space-around">
        <div>
          <SectionRow $justifyContent="space-between" $alignItems="center">
            <SectionImageContainer>
              <SectionVideo autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
                <SectionImage
                  src={image}
                  alt="Oath map"
                  title="Your browser does not support the <video> tag"
                />
              </SectionVideo>
            </SectionImageContainer>
            <SectionContent>
              <H2>Strategy guided by real-time data</H2>
              <SectionSubheader>
                Oath combines the power of data analytics with expert political strategy. Our
                proprietary Impact Score evaluates every candidate’s competitiveness, stakes, and
                financial need. The higher the score, the greater the value of your donation in that
                race.
              </SectionSubheader>
              <LandingButton href={getPathWithRef('/impact_score')}>Learn more</LandingButton>
            </SectionContent>
          </SectionRow>
        </div>
      </Row>
    </LearnMoreSectionContainer>
  );
};

import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { Row, RowToGrid } from 'components/Row';
import { ButtonBrand } from 'components/ButtonBrand';
import { Eyebrow, H3, H4 } from 'components/Typography';
import { TopIssueCard } from 'components/Cards/TopIssueCard';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { getPathWithRef } from 'helpers/utils';
import {
  Container,
  Description,
  InnerGradientBox,
  StyledImage,
  Title,
  TopIssueContainer,
} from './styled';

export const OtherIssues = ({ setsData, allTags, tagsFullList }) => {
  const setTags = setsData?.set_tags?.map(set => set.tag_id);
  const issues = tagsFullList
    ?.filter(tag => setTags?.includes(tag.id))
    ?.map(tag => ({
      ...tag,
      raised: setsData?.set_tags?.find(set => set.tag_id === tag.id)?.tag_fundraising_raised || '',
    }));
  const otherIssues = issues?.filter(tag => tag.id !== allTags[0].id);

  return (
    <Container $textAlign="center">
      <Title>Other Oath Issues</Title>
      <Description>Help us get to our $1 million goal by contributing to other issues</Description>
      <Spacing $size={16} />
      <RowToGrid $justifyContent="center">
        {otherIssues?.slice(0, 2)?.map((issue, index) => (
          <TopIssueContainer key={index}>
            <TopIssueCard issue={issue} />
          </TopIssueContainer>
        ))}
        <GradientBorderCard borderType="full" width="375px">
          <InnerGradientBox>
            <Eyebrow $color={theme.colors.inkBlue}>can’t decide?</Eyebrow>
            <H3>Split your contribution to all {issues?.length} issues</H3>
            <Spacing $size={16} />
            {issues?.map(issue => (
              <Row $justifyContent="center" key={issue.id}>
                <StyledImage
                  alt={`${issue.value} graphic`}
                  src={`https://storage.googleapis.com/oath_1/tags/icon/tag_${issue.id}.png`}
                />
                <Spacing $isVertical $size={8} />
                <H4>{issue.value}</H4>
              </Row>
            ))}
            <Spacing $size={24} />
            <ButtonBrand
              href={getPathWithRef(`/donate?p=${setsData?.querystring}`)}
              target="_blank"
            >
              {setsData?.set_misc?.donate_custom_1 || `DONATE TO ALL ${issues?.length} ISSUES`}
            </ButtonBrand>
          </InnerGradientBox>
        </GradientBorderCard>
      </RowToGrid>
    </Container>
  );
};

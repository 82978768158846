import { FC } from 'react';
import theme from 'config/theme';
import Icon from 'components/Icon';
import { Row } from 'components/Row';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { BodySmall, Eyebrow, H4 } from 'components/Typography';
import { galleryDate } from 'helpers/date';
import { getPathWithRef } from 'helpers/utils';
import { GalleryTypes } from './GalleryTypes';
import { Container, StyledImage } from './styled';

interface Props {
  media_id: string;
  title: string;
  summary: string;
  published_date: string;
  gallery_type: string;
  url: string;
}

export const MediaCard: FC<Props> = ({
  media_id,
  title,
  summary,
  published_date,
  gallery_type,
  url,
}) => {
  return (
    <Container>
      <Eyebrow $color={theme.colors.inkBlue}>{galleryDate(published_date)}</Eyebrow>
      <H4>{title}</H4>
      <Row>
        <Icon name={GalleryTypes[gallery_type]} size={18} color={theme.colors.gray} />
        <Spacing $isVertical $size={6} />
        <BodySmall $color={theme.colors.gray} $fontType="italic">
          {gallery_type}
        </BodySmall>
      </Row>
      <Spacing $size={12} />
      <BodySmall>{summary}</BodySmall>
      <Spacing $size={60} />
      <StyledImage
        alt={`Media ${title}`}
        src={`https://storage.googleapis.com/oath_1/static_content/gallery/image_${media_id}.png`}
      />
      <Spacing $size={8} />
      <Link href={getPathWithRef(url || '')} target="_blank">
        <BodySmall>Learn More</BodySmall>
      </Link>
    </Container>
  );
};

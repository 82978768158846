import Spacing from 'components/Spacing';
import { RowToGrid } from 'components/Row';
import { Body } from 'components/Typography';
import { listItems } from './constants';
import { HowItWorksContainer, BigText, StyledImage, InfoContainer, ItemTitle } from './styled';

export const HowItWorks = () => {
  return (
    <HowItWorksContainer $textAlign="center">
      <BigText>How It Works</BigText>
      <Spacing $size={20} />
      <RowToGrid $justifyContent="space-around">
        {listItems.map(item => (
          <InfoContainer
            key={item.id}
            $width="25%"
            $textAlign="center"
            $justifyItems="center"
            $alignSelf="flex-start"
          >
            <StyledImage
              alt={`${item.title} logo`}
              src={`https://storage.googleapis.com/oath_1/static_content/monthly/monthly_how_${item.id}.png`}
            />
            <ItemTitle>{item.title}</ItemTitle>
            <Spacing $size={4} />
            <Body>{item.subtitle}</Body>
          </InfoContainer>
        ))}
      </RowToGrid>
    </HowItWorksContainer>
  );
};

import axios from 'axios';
import { Dispatch } from 'redux';
import PAY_TYPES, { PayRequestData } from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';
import { datadogLogs } from '@datadog/browser-logs';
import { getMessageFromAxiosError } from 'helpers/errors';
import { noop } from 'helpers/utils';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const postPay =
  (body: PayRequestData) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(PAY_TYPES.payStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAY}`;

    try {
      const response = await axios.post(url, body, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(PAY_TYPES.paySuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(PAY_TYPES.payError(error.message));
      return Promise.reject(error);
    }
  };

export const postPayLoggedOut =
  (body: PayRequestData) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(PAY_TYPES.payStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAY}/logged_out`;

    try {
      const response = await axios.post(url, body, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(PAY_TYPES.paySuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(PAY_TYPES.payError(error.message));
      return Promise.reject(error);
    }
  };

export const postPayWithToken =
  (body: PayRequestData, tokenType: 'stored_token' | 'one_time_token', isLoggedIn: boolean) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(PAY_TYPES.payStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAY}/${tokenType}`;
    const payHeaders = headers;
    if (isLoggedIn) {
      payHeaders['Authorization'] = `Bearer ${getSiteAccessToken()}`;
    } else {
      payHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY;
    }

    try {
      const response = await axios.post(url, body, {
        headers: payHeaders,
      });
      dispatch(PAY_TYPES.paySuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(PAY_TYPES.payError(error.message));
      return Promise.reject(error);
    }
  };

export const getPayAuthToken = async (): Promise<any> => {
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAY}/auth-token`;

  try {
    const response = await axios.get(url, {
      headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
    });
    return Promise.resolve(response);
  } catch (error: any) {
    try {
      datadogLogs.logger.warn(
        'Error occured calling GET /pay/auth-token',
        {},
        new Error(getMessageFromAxiosError(error))
      );
    } catch (e) {
      noop();
    }
    return Promise.reject(error);
  }
};

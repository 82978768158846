import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { availableForms } from './constants';
import { Container } from './styled';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
  }
}

export const FormsScreen = () => {
  // const form = new URL(window.location.href).pathname.split('/').at(-1);
  const { form } = useParams();
  const navigate = useNavigate();
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (!form || !Object.keys(availableForms).includes(form)) {
      navigate('/');
      return;
    }

    const formContainer = document.getElementById('hs-form-container');
    if (formContainer) {
      formContainer.innerHTML = '';
    }

    if (formContainer && !scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.async = true;

      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '43762772',
            formId: availableForms[form],
            target: '#hs-form-container',
          });
          scriptLoaded.current = true;
        }
      };

      document.body.appendChild(script);
    }
  }, [form, navigate]);

  return (
    <>
      <Bar />
      <Container>
        <div id="hs-form-container" />
      </Container>
      <Footer />
    </>
  );
};
